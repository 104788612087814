@media (max-width:639px) {
    h1 {
        margin-bottom: 0.5rem !important;
        margin-top: 0.5rem !important;
    }

    .first-page {
        display: flex;
        flex-direction: column;
    }

    .slide-content {
        display: flex !important;
        flex-direction: column;
        justify-content: center;
    }

    .about {
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        gap: 0rem;
        margin: 1% 0% 1.5% 0%;
    }

    .about-content {
        text-align: center;
    }

    /* ------------------------ name --------------------*/
    .section-title {
        font-size: 3rem !important;
        font-weight: 600;
        text-align: center;
    }

    .my-name {
        font-size: 2rem;
        font-weight: 600;
    }

    /* ------------------------ roles --------------------*/
    .my-roles {
        font-size: 1rem !important;
        font-weight: 500;
        margin-bottom: 3%;
    }

    /* ------------------------ qualities --------------------*/
    .qualities-list-container {
        display: flex;
        height: 1.25rem !important;
        margin-bottom: 3%;
        overflow: hidden;
        justify-content: center;
        align-items: flex-start;
    }

    .my-qualities {
        font-size: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    /* animation */
    .qualities-list-container:before,
    .qualities-list-container:after {
        -webkit-animation-name: opacity;
        -webkit-animation-duration: 2s;
        -webkit-animation-iteration-count: infinite;
        animation-name: opacity;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        overflow: hidden;
    }

    .qualities-list {
        margin: 0;
        list-style-type: none;
        padding: 0;
        display: flex;
        flex-direction: column;
        width: fit-content;

        /* animation */
        -webkit-animation-name: change;
        -webkit-animation-duration: 10s;
        -webkit-animation-iteration-count: infinite;
        animation-name: change;
        animation-duration: 10s;
        animation-iteration-count: infinite;
    }

    .quality {
        color: #454ade;
        width: fit-content;
        height: fit-content;
        /* padding-bottom: 0.5rem; */
    }

    /* ----------- animation ------------*/

    @keyframes change {

        0%,
        22%,
        100% {
            transform: translate3d(0, 0%, 0);
        }

        25%,
        47% {
            transform: translate3d(0, -32.5%, 0);
        }

        50%,
        72% {
            transform: translate3d(0, -65%, 0);
        }

        75%,
        97% {
            transform: translate3d(0, -32.5%, 0);
        }
    }

    /* ------------------------ end qualities --------------------*/

    .last-about-line {
        font-size: 0.8rem !important;
    }

    .my-work {
        margin-top: 0rem !important;
        margin-bottom: 5rem !important;
    }

    .profile-image {
        width: 100%;
    }

    .fa-chevron-down {
        padding-bottom: 7.5%;
    }

    /* ------------------------ work --------------------*/
    .work-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        margin: 2rem;
        align-items: center;
        gap: 3rem;
        padding-bottom: 2rem;
        margin-bottom: 4rem !important;
    }

    .work-information {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 20rem
    }

    .project-information {
        text-align: center;
    }

    .project-description {
        text-align: center;
        margin-bottom: 1.25rem
    }

    .project-image {
        display: flex;
        width: 15rem;
        height: 15rem;
        background-color: #F1F1F1;
        border: 1px solid black;
        border-radius: 4rem !important;
        overflow: hidden !important;
    }

    .project-title {
        font-weight: 800;
        font-size: 1.5rem !important;
        text-align: center;
    }
}