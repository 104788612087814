@media (max-width: 900px) {
  .about-image-description {
    display: grid;
    grid-template-rows: auto 1fr;
    margin: 1.5rem 0rem;
    gap: 1rem
  }
}

@media (min-width: 901px) {
  .about-image-description {
    display: grid;
    grid-template-columns: 1fr 2.5fr;
    margin: 1.5rem 0rem;
    gap: 5rem
  }
}

@media (min-width: 1280px) {
  .about-description {
    font-size: 1.15rem !important;
  }
  .about-page {
    font-size: 1.15rem !important;
  }
}

@media (min-width: 2500px) {
  .about-description {
    font-size: 1.5rem !important;
  }
}

@media (max-width: 1279px){
  .about-page {
    font-size: 1rem;
  }
  .about-description {
    font-size: 1rem;
  }
}