.fp-slide {
    position: relative !important;
    display: flex !important;
    align-content: center;
}

/* ----------- animation ------------*/
/* animation */
.qualities-list-container:before,
.qualities-list-container:after {
    -webkit-animation-name: opacity;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-name: opacity;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    overflow: hidden;
}

.qualities-list {
    margin: 0;
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
    color: #454ade;

    /* animation */
    -webkit-animation-name: change;
    -webkit-animation-duration: 10s;
    -webkit-animation-iteration-count: infinite;
    animation-name: change;
    animation-duration: 10s;
    animation-iteration-count: infinite;
}

.project-key-info {
    text-align: center;
}

@keyframes change {

    0%,
    22%,
    100% {
        transform: translate3d(0, 0%, 0);
    }

    25%,
    47% {
        transform: translate3d(0, -32.5%, 0);
    }

    50%,
    72% {
        transform: translate3d(0, -65%, 0);
    }

    75%,
    97% {
        transform: translate3d(0, -32.5%, 0);
    }
}

@media(min-width: 1280px) {
    .project-writeup-image-60 {
        max-width: 60rem !important;
    }

    .project-writeup-image-50 {
        max-width: 50rem !important;
    }

    .project-writeup-image-40 {
        max-width: 40rem !important;
    }
    
    .project-writeup-image-30 {
        max-width: 30rem !important;
    }

    .first-page{
        margin-bottom: 6rem;
    }

    .about-content {
        justify-self: start;
        padding-left: 4%;
    }

    .about {
        display: grid;
        justify-items: center;
        align-items: center;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 0;
    }

    .my-name{
        font-size: 2.5rem;
    }

    .section-title{
        font-size: 2.5rem;
        margin-bottom: 1rem !important;
    }

    h1 {
        margin-bottom: 0rem !important;
    }

    /* ------------------------ roles --------------------*/
    .my-roles {
        font-size: 2rem;
        font-weight: 500;
        margin-bottom: 3%;
    }

    /* ------------------------ qualities --------------------*/
    .qualities-list-container {
        display: flex;
        height: 1.75rem;
        margin-bottom: 3%;
        overflow: hidden;
    }

    .my-qualities {
        font-size: 1.3rem;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
    }

    .profile-image {
        width: 75%;
        justify-self: end;
    }
}

@media(min-width: 1024px){
    .project-writeup-image-60 {
        max-width: 60rem !important;
    }

    .project-writeup-image-50 {
        max-width: 50rem !important;
    }

    .project-writeup-image-40 {
        max-width: 40rem !important;
    }
    
    .project-writeup-image-30 {
        max-width: 30rem !important;
    }

    .work-container {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        padding-bottom: 2rem !important;
        align-items: flex-start;
        gap: 1rem !important;
        margin-bottom: 6rem !important;
    }

    .work-information {
        display: flex;
        flex-direction: column;
        gap: 0.25rem !important;
        width: 20rem
    }

    .project-description {
        text-align: center;
    }

    .project-information {
        font-size: 1rem;
    }

    .project-title {
        font-size: 1.5rem
    }

    .project-image {
        display: flex;
        width: 20rem;
        height: 20rem;
        background-color: #F1F1F1;
        border: 0.05rem solid black;
        border-radius: 4rem !important;
        overflow: hidden !important;
    }
}

@media(min-width: 640px) and (max-width: 1023px){
    .project-writeup-image-60 {
        max-width: 60rem !important;
    }

    .project-writeup-image-50 {
        max-width: 50rem !important;
    }

    .project-writeup-image-40 {
        max-width: 40rem !important;
    }

    .project-writeup-image-30 {
        max-width: 30rem !important;
    }
    
    .first-page {
        display: flex;
        flex-direction: column;
    }

    .slide-content {
        display: flex !important;
        flex-direction: column;
        justify-content: center;
    }

    .about {
        display: flex;
        flex-direction: column;
        justify-items: center;
        align-items: center;
        gap: 0.5rem !important;
    }

    .about-content {
        text-align: center;
    }

    /* ------------------------ name --------------------*/
    .section-title {
        font-size: 5rem;
        font-weight: 600;
        text-align: center;
        margin-bottom: 2rem !important;
    }

    .my-name {
        font-size: 3rem;
        font-weight: 600;
    }

    /* ------------------------ roles --------------------*/
    .my-roles {
        font-size: 1.5rem;
        font-weight: 500;
        margin-bottom: 3%;
    }

    /* ------------------------ qualities --------------------*/
    .qualities-list-container {
        display: flex;
        height: 1.75rem;
        margin-bottom: 3%;
        overflow: hidden;
    }

    .my-qualities {
        font-size: 1.5rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .quality {
        color: #454ade;
        width: fit-content;
        height: fit-content;
        /* padding-bottom: 0.5rem; */
    }

    /* ------------------------ end qualities --------------------*/

    .last-about-line {
        font-size: 1.25rem;
    }

    .profile-image {
        width: 50%;
    }

    .fa-chevron-down {
        padding-bottom: 7.5%;
    }

    /* ------------------------ work --------------------*/
    .work-container {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        gap: 3rem !important;
        padding-bottom: 2rem !important;
    }

    .work-information {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 20rem
    }

    .project-information {
        text-align: center;
        font-size: 1.25rem;
    }

    .project-description {
        text-align: center;
    }

    .project-image {
        display: flex;
        width: 20rem;
        height: 20rem;
        background-color: #F1F1F1;
        border: 0.05rem solid black;
        border-radius: 4rem !important;
        overflow: hidden !important;
    }

    .project-title {
        font-weight: 800;
        font-size: 2rem
    }
}