.section-title{
    text-align: center;
}

@media (min-height: 1351px) {
    /* ------------------------ name --------------------*/
    .section-title{
        font-size: 5rem !important;
        margin-bottom: 2rem !important;
    }
    
    .project-writeup-image-60 {
        max-width: 60rem !important;
    }

    .project-writeup-image-50 {
        max-width: 50rem !important;
    }

    .project-writeup-image-40 {
        max-width: 40rem !important;
    }

    .project-writeup-image-30 {
        max-width: 30rem !important;
    }

    .my-name{
        font-size: 4rem !important;
    }
    
    /* ------------------------ roles --------------------*/
    .my-roles {
        font-size: 3rem !important;
    }

    /* ------------------------ qualities --------------------*/
    .qualities-list-container {
        display: flex !important;
        height: 2.5rem !important;
        margin-bottom: 3% !important;
        overflow: hidden !important;
    }
    
    .my-qualities {
        font-size: 2.25rem !important;
        display: flex !important;
    }
    /* ------------------------ end qualities --------------------*/
    
    .last-about-line{
        font-size: 1.75rem !important;
    }

    .project-title {
        font-size: 3rem !important
    }

    .project-image {
        display: flex;
        width: 30rem !important; 
        height: 30rem !important; 
    }

    .project-information {
        font-size: 2rem !important;
    }

    .work-information {
        display: flex; 
        flex-direction: column;
        align-items: flex-start; 
        width: 30rem !important
    }

    .my-work {
        font-size: 1.5rem !important;
    }
}

@media(min-height:916px) and (max-height:1350px) {
    h1 {
        margin: 0.25rem 0rem !important;
    }

    .project-writeup-image-60 {
        max-width: 60rem !important;
    }

    .project-writeup-image-50 {
        max-width: 50rem !important;
    }

    .project-writeup-image-40 {
        max-width: 40rem !important;
    }
    
    .project-writeup-image-30 {
        max-width: 30rem !important;
    }

    .profile-image {
        width: 50%;
    }

    /* ------------------------ name --------------------*/
    .section-title{
        font-size: 5rem !important;
        font-weight: 600;
        margin-bottom: 2rem !important;
    }
    
    .my-name{
        font-size: 3rem !important;
        font-weight: 600;
    }
    
    /* ------------------------ roles --------------------*/
    .my-roles {
        font-size: 1.5rem !important;
        font-weight: 500;
        margin-bottom: 3%;
    }
    
    /* ------------------------ qualities --------------------*/
    .qualities-list-container {
        display: flex;
        height: 1.75rem !important;
        margin-bottom: 3%;
        overflow: hidden;
    }
    
    .my-qualities {
        font-size: 1.5rem;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    
    /* animation */
    .qualities-list-container:before, .qualities-list-container:after{
    -webkit-animation-name: opacity;
    -webkit-animation-duration: 2s;
    -webkit-animation-iteration-count: infinite;
    animation-name: opacity;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    overflow: hidden;
    }
    
    .qualities-list {
    margin: 0;
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
    
    /* animation */
    -webkit-animation-name: change;
    -webkit-animation-duration: 10s;
    -webkit-animation-iteration-count: infinite;
    animation-name: change;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    }
    
    .quality{
    width: fit-content;
    height: fit-content;
    /* padding-bottom: 0.5rem; */
    }
    
    /* ----------- animation ------------*/
    
    @keyframes change {
    0%, 22%, 100% {transform:translate3d(0,0%,0);}
    25%, 47% {transform:translate3d(0,-32.5%,0);}
    50%,72% {transform:translate3d(0,-65%,0);}
    75%,97% {transform:translate3d(0,-32.5%,0);}
    }
    
    /* ------------------------ end qualities --------------------*/
    
    .last-about-line{
        font-size: 1.25rem !important;
    }
}

@media (max-height: 660px) {
    
    .slide-content{
        justify-content: flex-start !important;
        margin-top: 2rem;
    }

    .my-name{
        font-size: 1.5rem !important;
        font-weight: 600;
    }
    
    .profile-image {
    width: 75% !important;
    }
}