@media (min-width: 1280px) {
    .project-layout {
        display: grid;
        grid-template-columns: 1fr 8fr;
        grid-gap: 2rem;
    }

    .table-of-contents {
        display: flex;
        flex-direction: column;
        margin-top: 2rem;
        position: sticky !important; 
        top: 2rem !important
    }

    .toc-toc {
        display: flex; 
        flex-direction: column;
        gap: 1rem;
        position: sticky !important;
        top: 4rem !important;
    }

    .section-body {
        font-size: 1.15rem;
        margin-bottom: 3rem !important;
    }

    .project-section-title {
        margin-top: 3rem !important;
        font-size: 2rem !important;
    }

    .project-subsection-title {
        font-size: 1.5rem !important;
        margin-top: 2rem !important
    }

    .project-section-h4 {
        font-size: 1.25rem !important;
    }
}

@media (max-width: 1279px) {
    .project-layout {
        display: grid;
        grid-template-columns: 1fr !important;
    }

    .table-of-contents {
        display: none !important;
    }

    .section-body {
        font-size: 1rem;
        margin-bottom: 3rem !important;
    }
    
    .project-section-title {
        margin-top: 3rem !important;
        font-size: 2rem !important;
    }
    
    .project-subsection-title {
        font-size: 1.5rem !important;
        margin-top: 2rem !important
    }

    .project-section-h4 {
        font-size: 1.25rem !important;
    }
}