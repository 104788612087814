img {
  max-width: 100%;
  display: block;
}

.navbar {
  padding: 0.25rem 1rem;
  position: sticky;
  width: 100%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  color: #454ade;
  background-color: #FCEFEF;
  border-bottom: .1rem solid #454ade;
  top: 0px;
  z-index: 999;
}

.home{
  font-weight: 600;
}

@media (min-height: 1430px)
{
  .menu-item, .nav-link, .copyright {
    font-size: 1.5rem;
  }
}

@media (max-height: 1429px)
{
  .menu-item, .nav-link {
    font-size: 1.25rem;
  }
}

@media (max-height: 1000px)
{
  .menu-item, .nav-link {
    font-size: 1rem;
  }
}

@media (max-width: 639px)
{
  .menu-large {
    display: none;
  }
  
  .menu-small{
    display: block;
  }
}

@media(min-width: 640px)
{
  .menu-large{
    display: block;
  }

  .menu-small{
    display: none;
  }
}

.menu-item{
  display: inline;
  padding-left: 1rem;
  font-weight: 600;
}

.navbar::after, .footer:after{
  clear: both;
}

.footer{
  position: fixed;
  z-index: 70;
  bottom: 0;
  width: 100%;
  padding: 1rem;
  color: #454ade;
  z-index: 998;
}

.copyright{
  float: left;
}

.contact{
  float: right;
}

* {
    box-sizing: border-box;
  }

#basic-menu li.active a, #basic-menu li a.active{
  color: #a30b37;
}

.content {
  margin-top: 3rem;
}