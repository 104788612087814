body {
  margin: 0;
  font-family: "Montserrat", sans-serif;
  background-color: #FCEFEF;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  display: flex;
  flex-direction: column;
  margin: 0;
  min-height: 100vh;
}


.content{
  flex: 1;
  max-width: 100vw;
  padding: 0rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.project-content{
  flex: 1;
  max-width: 100vw;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 3rem;
}